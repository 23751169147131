/* Основная палитра */
$color-primary-black: #1D1D1B;
$color-primary-blue: #3B85F7;

/* Акцентные цвета */
$color-text-accent: #747F94;
$color-input-accent: #7B869B;
$color-text-white: #FFFFFF;
$color-text-grey: #9E9E9E;
$color-text-blue: #26365C;
$color-text-aqua: #3B85F7;
$color-text-black-green: #343B47;
$color-text-red: #C53131;
$color-text-brown: #A52A2AFF;

/* Цвета фона */
$color-bg-white: #FFFFFF;
$color-bg-accent: #7B869B;
$color-bg-ghost-white: #F6F8FB;
$color-bg-smoky-white: #F8F8F8;
$color-bg-grey: #EBEBEB;
$color-bg-black: #1D1D1B;
$color-bg-grey-light: #F4F7FA;
$color-bg-grey-dark: #747F94;
$color-bg-blue: #3B85F7;
$color-bg-disabled: #F5F5F5;
$color-bg-hidden: #061c2e80;
$color-bg-indicator: rgba(52, 59, 71, 0.23);
$color-bg-blue-light: #F5F8FD;
$color-bg-black-light: rgba(29, 29, 27, 0.5);
$color-bg-red: #DA3832;
$color-bg-footer: #2A2F4D;

/* Дополнительные цвета при действиях, для границ и разделителей */
$color-border-grey: #E5E5E5;
$color-border-light: #E2E5EB;
$color-border-blue: #3B85F7;
$color-border-green: #3B85F7;
$color-border-pink: #F7D3D2;
$color-divider-border: #B5BCCB;
$color-divider-dark: #989FAC;
$color-active-white: #FFFFFF;
$color-active-green: #06ce86;
$color-switcher: #343B47;
$color-blackout: rgba(6, 28, 46, 0.7);
$color-border-catalog: rgba(181, 188, 203, 0.56);
$color-border-compare: rgba(181, 188, 203, 0.38);
$color-border-grey-light: #D6D9E2;

/* Переменные для теней */
$shadow-light-01: rgba(0, 0, 0, 0.1);
$shadow-light-07: rgba(0, 0, 0, 0.07);
$shadow-light-08: rgba(0, 0, 0, 0.08);
$shadow-light-25: rgba(0, 0, 0, 0.25);
$shadow-grey: rgba(151, 165, 193, 0.2);