@import "color-palette";
@import "mixins";

.main-screen {
    position: relative;
    background: $color-bg-white;

    &__bg {
        background: url(/img/bg2_big.jpg) no-repeat 100% 100%;
        background-image: image-set(
                        '/img/bg2_big.webp' 1x,
                        '/img/bg2_big.jpg' 1x
        );
        height: 500px;
        width: 100%;
        position: absolute;
        background-size: cover;

        @media screen and (min-width: 1500px) {
            background: url(/img/bg2_big.jpg) no-repeat 100% 60%;
            background-image: image-set(
                            '/img/bg2_big.webp' 1x,
                            '/img/bg2_big.jpg' 1x
            );
            background-size: cover;
        }
    }

    &__blackout {
        position: absolute;
        background: $color-blackout;
        width: 100%;
        height: 500px;
        z-index: 3;
        top: 0;
    }

    &__content {
        @include container;
        z-index: 10;
        position: relative;
    }

    &__catalog {
        margin-top: 50px;

        &__catalog-error {
            margin: 2em auto;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}