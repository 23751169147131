@import "mixins";

.CardLink {
    text-decoration: none !important;
}

.BlogCard {
    border-radius: 12px;
    overflow: hidden;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    //Hover
    &:not(.DisableShadows):hover {
        box-shadow: 0 8px 48px rgb(151 165 193 / 20%);

        @media screen and (min-width: 500px) {
            position: relative;
            z-index: 1;
            transform: scale(1.01);
            transform: translateY(-5px);
        }

        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
    }

    //Children

    .Image {
        position: relative;
        width: 100%;
        height: 200px;
        min-height: 200px;
        @include MQ(M) {
            height: 30vw;
            min-height: 30vw;
            max-height: 300px;
        }
        @include MQ(S) {
            height: 60vw;
            min-height: 60vw;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .Content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .Title {
            font-weight: 700;
        }
        .Footer {
            margin-top: 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #747f94;
            font-size: 0.9rem;
            font-weight: 500;
            .Views {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                margin-left: 15px;
                .Icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
