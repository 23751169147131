@import "color-palette";

.MainPageTitle {
    padding-top: 150px;
    overflow: hidden;

    @media (max-width: 690px) {
        padding-top: 107px;
    }
    @media (max-width: 564px) {
        padding-top: 118px;
    }
    @media (max-width: 447px) {
        padding-top: 106px;
    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 117.5%;
        color: $color-text-white;
        margin: 0;
        @media (max-width: 430px) {
            font-size: 28px;
        }
    }

    &__label {
        font-weight: 700;
        font-size: 40px;
        line-height: 49px;
        color: $color-text-white;
        @media (max-width: 430px) {
            font-size: 36px;
        }
    }

    &__icons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(116px, 1fr));
        align-items: center;
        gap: 20px 30px;
        list-style: none;
        padding: 0;
        margin: 35px 0 76px;

        &-link {
            display: block;
            height: 100%;
            width: 116px;

            @media (max-width: 564px) {
                width: 100px;
            }
            @media (max-width: 500px) {
                width: 90px;
            }
        }

        @media (max-width: 1180px) {
            gap: 20px 20px;
        }
        @media (max-width: 1107px) {
            margin-bottom: 12px;
        }
        @media (max-width: 699px) {
            margin-bottom: 20px;
        }
        @media (max-width: 564px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        }
        @media (max-width: 500px) {
            grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
            gap: 16px 16px;
            margin-bottom: 30px;
        }
        @media (max-width: 447px) {
            gap: 8px 16px;
            margin-bottom: 8px;
        }
        @media (max-width: 430px) {
            margin-bottom: 26px;
        }
    }

    &__item {
        width: auto;
    }
}