@import "mixins";
@import "color-palette";

.BrandsList {
    margin-top: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__inner {
        padding: 0 30px;
        @include flexList;
        row-gap: 30px;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 50px;
    }

    &__item {
        padding-left: 20px;
        width: 25%;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;

        @media (max-width: 1000px) {
            width: 33%;
        }

        @media (max-width: 700px) {
            width: 50%;
        }

        @media (max-width: 450px) {
            font-size: 14px;
            line-height: 19px;
        }

        &-link {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-wrapper {
            position: relative;
            width: 50px;
            height: 20px;
            display: flex;
            justify-content: center;
        }

        &-icon {
            position: absolute;
            color: transparent;
            object-fit: contain;
        }

        &-label {
            margin-left: 13px;
            @media (max-width: 450px) {
                margin-left: 3px;
            }
        }
    }

    &__toggler {
        font-weight: 600;
        font-size: 15px;
        font-style: normal;
        line-height: 18px;
        color: $color-text-aqua;
    }
}